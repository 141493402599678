import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"
import { getElements } from "@oddcamp/js-utils/src/selector"
import { addEventListener } from "@oddcamp/js-utils/src/event"
import { delegateEventListener } from "@oddcamp/js-utils/src/event"
import bodymovin from "lottie-web"
import init from "../utils/init"
import { throttle } from "lodash"

// user nav

init(() => {
  const container = document.querySelector(`.header__bar__user`)
  if (!container) return

  const button = container.querySelector(`:scope > button`)

  button.addEventListener(`click`, () => container.classList.toggle(`--active`))

  document.addEventListener(`click`, (e) => {
    if (!e.target.closest(`.header__bar__user`)) {
      container.classList.remove(`--active`)
    }
  })

  document.addEventListener(`keyup`, (e) => {
    if (e.key == `Escape`) {
      container.classList.remove(`--active`)
    }
  })

  const adminContainer = document.querySelector(`.admin-bar__user`)
  if (!adminContainer) return
})

// admin user nav

delegateEventListener(`.admin-bar__user button`, `click`, () => {
  addClass(`.admin-bar__user`, `--active`)
})

init(() => {
  const adminContainer = document.querySelector(`.admin-bar__user`)
  if (!adminContainer) return

  document.addEventListener(`click`, (e) => {
    if (!e.target.closest(`.admin-bar__user`)) {
      removeClass(`.admin-bar__user`, `--active`)
    }
  })

  document.addEventListener(`keyup`, (e) => {
    if (e.key == `Escape`) {
      removeClass(`.admin-bar__user`, `--active`)
    }
  })
})

// admin nav

init(() => {
  const toggleEl = document.querySelector(`.admin-bar__toggle button`)
  if (!toggleEl) return

  const toggleContainer = document.querySelector(`.admin-header`)

  toggleEl.addEventListener(`click`, () => {
    toggleEl.classList.toggle(`--active`)
    toggleContainer.classList.toggle(`--active`)
  })

  document.addEventListener(`click`, (e) => {
    if (
      !e.target.closest(`.admin-header`) &&
      !e.target.closest(`.admin-bar__toggle`)
    ) {
      toggleContainer.classList.remove(`--active`)
      toggleEl.classList.remove(`--active`)
    }
  })
})

// admin nav toggles

init(() => {
  const toggleEls = getElements(`.admin-header__nav button`)
  const targetEls = getElements(`.admin-header__nav .-sub`)
  if (!toggleEls.length) return

  addEventListener(toggleEls, `click`, (e, triggerEl) => {
    const isActive = triggerEl.classList.contains(`--active`)
    removeClass(toggleEls, `--active`)
    removeClass(targetEls, `--active`)

    if (!isActive) {
      triggerEl.classList.add(`--active`)
      triggerEl.nextElementSibling.classList.add(`--active`)
    }
  })
})

// mobile navs toggles

init(() => {
  const toggleEls = getElements(`.header__main__toggles button`)
  const targetEls = getElements(`.header__main__nav, .header__main__user`)
  if (!toggleEls.length) return

  addEventListener(toggleEls, `click`, (e, triggerEl) => {
    const isActive = triggerEl.classList.contains(`--active`)

    removeClass(toggleEls, `--active`)
    removeClass(targetEls, `--active`)

    document.documentElement.classList.toggle(
      `--disable-scroll-mobile`,
      !isActive
    )

    if (!isActive) {
      triggerEl.classList.add(`--active`)

      targetEls
        .find((el) =>
          el.classList.contains(`header__main__${triggerEl.dataset.target}`)
        )
        .classList.add(`--active`)
    }
  })
})

// animated logo

init(() => {
  const animatedLogo = document.querySelector(`#animated-logo`)
  if (!animatedLogo) return

  const staticLogo = document.querySelector(`#site-logo`)

  bodymovin.loadAnimation({
    container: document.getElementById(`animated-logo`),
    path: `/logo/animated.json`,
    renderer: `svg`,
    loop: true, // Optional
    autoplay: true, // Optional
    name: `Volontärbyrån Logo`, // Name for future reference. Optional.
  })

  if (staticLogo) staticLogo.style.opacity = 0
})

// sticky header color change on startpage
init(() => {
  const header = document.querySelector(`.default-header--home`)
  const menu = document.querySelector(`.default-header__menu--home`)
  if (!header) return

  const updateScrollState = () => {
    const scrollTop = document.scrollingElement.scrollTop
    if (scrollTop > 80) {
      header.classList.add(`--scrolled`)
      menu.classList.add(`--scrolled`)
    } else {
      header.classList.remove(`--scrolled`)
      menu.classList.remove(`--scrolled`)
    }
  }

  // initially update scroll state on load
  updateScrollState()

  const throttledOnScroll = throttle(updateScrollState, 100)

  document.addEventListener(`scroll`, throttledOnScroll)
})
